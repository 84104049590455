import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/textures/add',
      name: 'addTexture',
      component: () => import('@/views/texture/CreateTexture.vue'),
      meta: {
        pageTitle: 'Ajouter une texture',
        breadcrumb: [
          {
            text: 'Ajouter une texture',
            active: true,
          },
        ],
      },
    },
    {
      path: '/textures',
      name: 'textures',
      component: () => import('@/views/texture/Textures.vue'),
      meta: {
        pageTitle: 'Textures',
        breadcrumb: [
          {
            text: 'Textures',
            active: true,
          },
        ],
      },
    },
    {
      path: '/betas',
      name: 'betas',
      component: () => import('@/views/beta/Betas.vue'),
      meta: {
        pageTitle: 'Beta testeurs',
        breadcrumb: [
          {
            text: 'Betas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/stockists',
      name: 'stockists',
      component: () => import('@/views/stockist/Stockists.vue'),
      meta: {
        pageTitle: 'Stockists',
        breadcrumb: [
          {
            text: 'Stockists',
            active: true,
          },
        ],
      },
    },
    
    {
      path: '/stockist/edit/:id',
      name: 'editStockist',
      component: () => import('@/views/stockist/EditStockist.vue'),
      meta: {
        pageTitle: 'Modifier un point de vente',
        breadcrumb: [
          {
            text: 'Modifier un point de vente',
            active: true,
          },
        ],
      },
    },
    {
      path: '/order-item/:id',
      name: 'orderItemView',
      component: () => import('@/views/orders/OrderItemViewer.vue'),
      meta: {
        pageTitle: 'Voir Order Item',
        breadcrumb: [
          {
            text: 'Voir Order Item',
            active: true,
          },
        ],
      },
    },
    {
      path: '/glasses/edit/:id',
      name: 'editGlass',
      component: () => import('@/views/glass/EditGlass.vue'),
      meta: {
        pageTitle: 'Modifier un produit',
        breadcrumb: [
          {
            text: 'Modifier un produit',
            active: true,
          },
        ],
      },
    },
   
    {
      path: '/model-part/edit/:id',
      name: 'editModelPart',
      component: () => import('@/views/model-parts/EditModelPart.vue'),
      meta: {
        pageTitle: 'Modifier un model part',
        breadcrumb: [
          {
            text: 'Modifier un model part',
            active: true,
          },
        ],
      },
    },
    {
      path: '/model-part/add/:id',
      name: 'addModelPart',
      component: () => import('@/views/model-parts/CreateModelPart.vue'),
      meta: {
        pageTitle: 'Ajouter un model part',
        breadcrumb: [
          {
            text: 'Ajouter un model part',
            active: true,
          },
        ],
      },
    },

    {
      path: '/beta/:id',
      name: 'detailBeta',
      component: () => import('@/views/beta/BetaDetail.vue'),
      meta: {
        pageTitle: 'Detail beta',
        breadcrumb: [
          {
            text: 'Detail beta',
            active: true,
          },
        ],
      },
    },

    {
      path: '/colors/edit/:id',
      name: 'editColor',
      component: () => import('@/views/color/EditColor.vue'),
      meta: {
        pageTitle: 'Modifier une couleur',
        breadcrumb: [
          {
            text: 'Modifier une couleur',
            active: true,
          },
        ],
      },
    },
    {
      path: '/promotions/edit/:id',
      name: 'editPromotion',
      component: () => import('@/views/promotion/EditPromotion.vue'),
      meta: {
        pageTitle: 'Modifier une promotion',
        breadcrumb: [
          {
            text: 'Modifier une promotion',
            active: true,
          },
        ],
      },
    },
    {
      path: '/textures/edit/:id',
      name: 'editTexture',
      component: () => import('@/views/texture/EditTexture.vue'),
      meta: {
        pageTitle: 'Modifier une texture',
        breadcrumb: [
          {
            text: 'Modifier une texture',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tints/edit/:id',
      name: 'editTint',
      component: () => import('@/views/tint/EditTint.vue'),
      meta: {
        pageTitle: 'Modifier une teinte',
        breadcrumb: [
          {
            text: 'Modifier une teinte',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tint-categories/edit/:id',
      name: 'editTintCategory',
      component: () => import('@/views/tint/EditTintCategory.vue'),
      meta: {
        pageTitle: 'Modifier une catégorie de teinte',
        breadcrumb: [
          {
            text: 'Modifier une catégorie de teinte',
            active: true,
          },
        ],
      },
    },
    {
      path: '/branches/edit/:id',
      name: 'editBranch',
      component: () => import('@/views/branchs/EditBranch.vue'),
      meta: {
        pageTitle: 'Modifier une branche',
        breadcrumb: [
          {
            text: 'Modifier une branche',
            active: true,
          },
        ],
      },
    },
    {
      path: '/branches/add',
      name: 'addBranch',
      component: () => import('@/views/branchs/CreateBranch.vue'),
      meta: {
        pageTitle: 'Ajouter une branche',
        breadcrumb: [
          {
            text: 'Ajouter une branche',
            active: true,
          },
        ],
      },
    },
    {
      path: '/branches',
      name: 'branches',
      component: () => import('@/views/branchs/Branchs.vue'),
      meta: {
        pageTitle: 'Branches',
        breadcrumb: [
          {
            text: 'Branches',
            active: true,
          },
        ],
      },
    },
    {
      path: '/community-picks/edit/:id',
      name: 'editCommunity',
      component: () => import('@/views/community/EditCommunityPick.vue'),
      meta: {
        pageTitle: 'Modifier un community pick',
        breadcrumb: [
          {
            text: 'Modifier un community pick',
            active: true,
          },
        ],
      },
    },
    {
      path: '/community-picks/add',
      name: 'addCommunity',
      component: () => import('@/views/community/CreateCommunityPick.vue'),
      meta: {
        pageTitle: 'Ajouter un community pick',
        breadcrumb: [
          {
            text: 'Ajouter un community pick',
            active: true,
          },
        ],
      },
    },
    {
      path: '/community-picks',
      name: 'community-picks',
      component: () => import('@/views/community/CommunityPicks.vue'),
      meta: {
        pageTitle: 'Community Picks',
        breadcrumb: [
          {
            text: 'Community Picks',
            active: true,
          },
        ],
      },
    },
    {
      path: '/glass-types/edit/:id',
      name: 'editGlassType',
      component: () => import('@/views/glass-type/EditGlassType.vue'),
      meta: {
        pageTitle: 'Modifier un type de verre',
        breadcrumb: [
          {
            text: 'Modifier un type de verre',
            active: true,
          },
        ],
      },
    },
    {
      path: '/glass-types/add',
      name: 'addGlassType',
      component: () => import('@/views/glass-type/CreateGlassType.vue'),
      meta: {
        pageTitle: 'Ajouter un type de verre',
        breadcrumb: [
          {
            text: 'Ajouter un type de verre',
            active: true,
          },
        ],
      },
    },
    {
      path: '/glass-types',
      name: 'glass-types',
      component: () => import('@/views/glass-type/GlassTypes.vue'),
      meta: {
        pageTitle: 'Types de verres',
        breadcrumb: [
          {
            text: 'Types de verres',
            active: true,
          },
        ],
      },
    },
    {
      path: '/refinements/edit/:id',
      name: 'editRefinement',
      component: () => import('@/views/refinement/EditRefinement.vue'),
      meta: {
        pageTitle: 'Modifier un affinement',
        breadcrumb: [
          {
            text: 'Modifier un affinement',
            active: true,
          },
        ],
      },
    },
    {
      path: '/refinements/add',
      name: 'addRefinement',
      component: () => import('@/views/refinement/CreateRefinement.vue'),
      meta: {
        pageTitle: 'Ajouter un affinement',
        breadcrumb: [
          {
            text: 'Ajouter un affinement',
            active: true,
          },
        ],
      },
    },
    {
      path: '/stockist/add',
      name: 'addStockist',
      component: () => import('@/views/stockist/CreateStockist.vue'),
      meta: {
        pageTitle: 'Ajouter un point de vente',
        breadcrumb: [
          {
            text: 'Ajouter un point de vente',
            active: true,
          },
        ],
      },
    },
    {
      path: '/refinements',
      name: 'refinements',
      component: () => import('@/views/refinement/Refinements.vue'),
      meta: {
        pageTitle: 'Affinements',
        breadcrumb: [
          {
            text: 'Affinements',
            active: true,
          },
        ],
      },
    },
    {
      path: '/processings/edit/:id',
      name: 'editProcessing',
      component: () => import('@/views/processing/EditProcessing.vue'),
      meta: {
        pageTitle: 'Modifier un traitement',
        breadcrumb: [
          {
            text: 'Modifier un traitement',
            active: true,
          },
        ],
      },
    },
    {
      path: '/processings/add',
      name: 'addProcessing',
      component: () => import('@/views/processing/CreateProcessing.vue'),
      meta: {
        pageTitle: 'Ajouter un traitement',
        breadcrumb: [
          {
            text: 'Ajouter un traitement',
            active: true,
          },
        ],
      },
    },
    {
      path: '/processings',
      name: 'processings',
      component: () => import('@/views/processing/Processings.vue'),
      meta: {
        pageTitle: 'Traitements',
        breadcrumb: [
          {
            text: 'Traitements',
            active: true,
          },
        ],
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/settings/Settings.vue'),
      meta: {
        pageTitle: 'Réglages',
        breadcrumb: [
          {
            text: 'Réglages',
            active: true,
          },
        ],
      },
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/orders/Orders.vue'),
      meta: {
        pageTitle: 'Commandes',
        breadcrumb: [
          {
            text: 'Commandes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/orders/detail/:id',
      name: 'orderDetail',
      component: () => import('@/views/orders/OrderDetail.vue'),
      meta: {
        pageTitle: 'Détail commande',
        breadcrumb: [
          {
            text: 'Détail commande',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/users/Users.vue'),
      meta: {
        pageTitle: 'Utilisateurs',
        breadcrumb: [
          {
            text: 'Utilisateurs',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user/view/:id',
      name: 'viewUser',
      component: () => import('@/views/users/ViewUser.vue'),
      meta: {
        pageTitle: 'Information utilisateur',
        breadcrumb: [
          {
            text: 'Information utilisateur',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user/edit/:id',
      name: 'editUser',
      component: () => import('@/views/users/EditUser.vue'),
      meta: {
        pageTitle: 'Modification utilisateurs',
        breadcrumb: [
          {
            text: 'Modification utilisateurs',
            active: true,
          },
        ],
      },
    },
    
    {
      path: '/user/delete/:id',
      name: 'deleteUser',
      component: () => import('@/views/users/DeleteUser.vue'),
      meta: {
        pageTitle: 'Suppression utilisateurs',
        breadcrumb: [
          {
            text: 'Suppression utilisateurs',
            active: true,
          },
        ],
      },
    }, 
    {
      path: '/user/add',
      name: 'addUser',
      component: () => import('@/views/users/CreateUser.vue'),
      meta: {
        pageTitle: 'Créer un utilisateur',
        breadcrumb: [
          {
            text: 'Créer un utilisateur',
            active: true,
          },
        ],
      },
    },

    {
      path: '/users-b2b',
      name: 'users-b2b',
      component: () => import('@/views/users-b2b/Users.vue'),
      meta: {
        pageTitle: 'Utilisateurs B2B',
        breadcrumb: [
          {
            text: 'Utilisateurs B2B',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users-b2b/view/:id',
      name: 'viewUserB2B',
      component: () => import('@/views/users-b2b/ViewUser.vue'),
      meta: {
        pageTitle: 'Information utilisateur B2B',
        breadcrumb: [
          {
            text: 'Information utilisateur B2B',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users-b2b/edit/:id',
      name: 'editUserB2B',
      component: () => import('@/views/users-b2b/EditUser.vue'),
      meta: {
        pageTitle: 'Modification du catalogue',
        breadcrumb: [
          {
            text: 'Modification du catalogue',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users-b2b/delete/:id',
      name: 'deleteUserB2B',
      component: () => import('@/views/users-b2b/DeleteUser.vue'),
      meta: {
        pageTitle: 'Suppression utilisateurs B2B',
        breadcrumb: [
          {
            text: 'Suppression utilisateurs B2B',
            active: true,
          },
        ],
      },
    }, 
    {
      path: '/users-b2b/add',
      name: 'addUserB2B',
      component: () => import('@/views/users-b2b/CreateUser.vue'),
      meta: {
        pageTitle: 'Créer un utilisateur B2B',
        breadcrumb: [
          {
            text: 'Créer un utilisateur B2B',
            active: true,
          },
        ],
      },
    },
    {
      path: '/renders',
      name: 'renders',
      component: () => import('@/views/renders/Renders.vue'),
      meta: {
        pageTitle: 'Renders',
        breadcrumb: [
          {
            text: 'Renders',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ratings',
      name: 'ratings',
      component: () => import('@/views/rating/Ratings.vue'),
      meta: {
        pageTitle: 'Avis clients',
        breadcrumb: [
          {
            text: 'Avis clients',
            active: true,
          },
        ],
      },
    },
    

    {
      path: '/rating/edit/:id',
      name: 'editRating',
      component: () => import('@/views/rating/EditRating.vue'),
      meta: {
        pageTitle: 'Modification avis client',
        breadcrumb: [
          {
            text: 'Modification avis client',
            active: true,
          },
        ],
      },
    },
    
    {
      path: '/rating/add',
      name: 'addRating',
      component: () => import('@/views/rating/CreateRating.vue'),
      meta: {
        pageTitle: 'Créer un avis client',
        breadcrumb: [
          {
            text: 'Créer un avis client',
            active: true,
          },
        ],
      },
    },
    {
      path: '/glass-options/edit/:id',
      name: 'editGlassOption',
      component: () => import('@/views/glass-option/EditGlassOption.vue'),
      meta: {
        pageTitle: 'Modifier une option de verre',
        breadcrumb: [
          {
            text: 'Modifier une option de verre',
            active: true,
          },
        ],
      },
    },
    {
      path: '/glass-options/add',
      name: 'addGlassOption',
      component: () => import('@/views/glass-option/CreateGlassOption.vue'),
      meta: {
        pageTitle: 'Ajouter une option de verre',
        breadcrumb: [
          {
            text: 'Ajouter une option de verre',
            active: true,
          },
        ],
      },
    },
    {
      path: '/glass-options',
      name: 'glass-options',
      component: () => import('@/views/glass-option/GlassOptions.vue'),
      meta: {
        pageTitle: 'Options de verres',
        breadcrumb: [
          {
            text: 'Options de verres',
            active: true,
          },
        ],
      },
    },
    {
      path: '/texture-styles/edit/:id',
      name: 'editTextureStyle',
      component: () => import('@/views/texture-style/EditTextureStyle.vue'),
      meta: {
        pageTitle: 'Modifier un style de texture',
        breadcrumb: [
          {
            text: 'Modifier un style de texture',
            active: true,
          },
        ],
      },
    },
    {
      path: '/texture-styles/add',
      name: 'addTextureStyle',
      component: () => import('@/views/texture-style/CreateTextureStyle.vue'),
      meta: {
        pageTitle: 'Ajouter un style de texture',
        breadcrumb: [
          {
            text: 'Ajouter un style de texture',
            active: true,
          },
        ],
      },
    },
    {
      path: '/texture-styles',
      name: 'texture-styles',
      component: () => import('@/views/texture-style/TextureStyles.vue'),
      meta: {
        pageTitle: 'Styles de texture',
        breadcrumb: [
          {
            text: 'Styles de texture',
            active: true,
          },
        ],
      },
    },
    {
      path: '/languages/edit/:id',
      name: 'editLanguage',
      component: () => import('@/views/languages/EditLanguage.vue'),
      meta: {
        pageTitle: 'Modifier une langue',
        breadcrumb: [
          {
            text: 'Modifier une langue',
            active: true,
          },
        ],
      },
    },
    {
      path: '/languages/add',
      name: 'addLanguage',
      component: () => import('@/views/languages/CreateLanguage.vue'),
      meta: {
        pageTitle: 'Ajouter une langue',
        breadcrumb: [
          {
            text: 'Ajouter une langue',
            active: true,
          },
        ],
      },
    },
    {
      path: '/languages',
      name: 'languages',
      component: () => import('@/views/languages/Languages.vue'),
      meta: {
        pageTitle: 'Langues',
        breadcrumb: [
          {
            text: 'Langues',
            active: true,
          },
        ],
      },
    },
    {
      path: '/translate',
      name: 'TranslationForm',
      component: () => import('@/views/languages/TranslationForm.vue'),
      meta: {
        pageTitle: 'Ajouter une traduction',
        breadcrumb: [
          {
            text: 'Ajouter une traduction',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tint-categories/add',
      name: 'addTintCategory',
      component: () => import('@/views/tint/CreateTintCategory.vue'),
      meta: {
        pageTitle: 'Ajouter une teinte',
        breadcrumb: [
          {
            text: 'Ajouter une teinte',
            active: true,
          },
        ],
      },
    },
    {
      path: '/promotions/add',
      name: 'addPromotion',
      component: () => import('@/views/promotion/CreatePromotion.vue'),
      meta: {
        pageTitle: 'Ajouter une promotion',
        breadcrumb: [
          {
            text: 'Ajouter une promotion',
            active: true,
          },
        ],
      },
    },

    {
      path: '/promotions',
      name: 'promotions',
      component: () => import('@/views/promotion/Promotions.vue'),
      meta: {
        pageTitle: 'Promotions',
        breadcrumb: [
          {
            text: 'Promotions',
            active: true,
          },
        ],
      },
    },
    
    {
      path: '/tint-categories',
      name: 'tint-categories',
      component: () => import('@/views/tint/TintCategories.vue'),
      meta: {
        pageTitle: 'Catégories de teintes',
        breadcrumb: [
          {
            text: 'Catégories de teintes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tints/add',
      name: 'addTint',
      component: () => import('@/views/tint/CreateTint.vue'),
      meta: {
        pageTitle: 'Ajouter une teinte',
        breadcrumb: [
          {
            text: 'Ajouter une teinte',
            active: true,
          },
        ],
      },
    },
    {
      path: '/tints',
      name: 'tints',
      component: () => import('@/views/tint/Tints.vue'),
      meta: {
        pageTitle: 'Teintes',
        breadcrumb: [
          {
            text: 'Teintes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sizes/edit/:id',
      name: 'editSize',
      component: () => import('@/views/size/EditSize.vue'),
      meta: {
        pageTitle: 'Modifier une taille',
        breadcrumb: [
          {
            text: 'Modifier une taille',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sizes/add',
      name: 'addSize',
      component: () => import('@/views/size/CreateSize.vue'),
      meta: {
        pageTitle: 'Ajouter une taille',
        breadcrumb: [
          {
            text: 'Ajouter une taille',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sizes',
      name: 'sizes',
      component: () => import('@/views/size/Sizes.vue'),
      meta: {
        pageTitle: 'Tailles',
        breadcrumb: [
          {
            text: 'Tailles',
            active: true,
          },
        ],
      },
    },
    {
      path: '/colors/add',
      name: 'addColor',
      component: () => import('@/views/color/CreateColor.vue'),
      meta: {
        pageTitle: 'Ajouter une couleur',
        breadcrumb: [
          {
            text: 'Ajouter une couleur',
            active: true,
          },
        ],
      },
    },
    {
      path: '/colors',
      name: 'colors',
      component: () => import('@/views/color/Colors.vue'),
      meta: {
        pageTitle: 'Couleurs',
        breadcrumb: [
          {
            text: 'Couleurs',
            active: true,
          },
        ],
      },
    },
    {
      path: '/glasses/add',
      name: 'addGlass',
      component: () => import('@/views/glass/CreateGlass.vue'),
      meta: {
        pageTitle: 'Ajouter un produit',
        breadcrumb: [
          {
            text: 'Ajouter un produit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/glasses',
      name: 'glasses',
      component: () => import('@/views/glass/Glasses.vue'),
      meta: {
        pageTitle: 'Lunettes',
        breadcrumb: [
          {
            text: 'Lunettes',
            active: true,
          },
        ],
      },
    },
    
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
